import Vue from 'vue'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'

import i18n from '@/libs/i18n'
import router from './router'
import store from './store'
import App from './App.vue'
import VueHtmlToPaper from 'vue-html-to-paper';
import authHelpers from "./utils/auth-helpers";

// Global Components
import './global-components'

// 3rd party plugins
import '@axios'
// import '@/libs/acl'
import '@/libs/portal-vue'
import '@/libs/clipboard'
import '@/libs/toastification'
// import '@/libs/sweet-alerts'
import '@/libs/vue-select'
import '@/libs/tour'

// require("dayjs/locale/ar-iq");
import moment from "moment";
// import { useQuery } from "vue-query";
// Axios Mock Adapter
// import '@/@ -db/db'
let basePath= process.env.VUE_APP_URL;


const options={
  "name": "_blank",
  "specs": [
    "width=1000",
    "fullscreen=no",
    "titlebar=yes",
    "scrollbars=yes"
  ],
  "styles": [
    "https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css",
    "https://unpkg.com/kidlat-css/css/kidlat.css",
    // "public/landscape.css",
    `${basePath}/css/QueuePaper.css`,
  ],
  "timeout": 1000,
  // "autoClose": true,
  // "windowTitle": "Vue Html To Paper - Vue mixin for html elements printing."
}


// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)

// Composition API
Vue.use(VueCompositionAPI)

Vue.use(VueHtmlToPaper, options);
//moment
Vue.prototype.$moment = moment;
// Vue.prototype.$useQuery = useQuery;


// Feather font icon - For form-wizard
// * Shall remove it if not using font-icons of feather-icons - For form-wizard
require('@core/assets/fonts/feather/iconfont.css') // For form-wizard

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.config.productionTip = false

Vue.directive("can", {
  bind: function (el, binding, vnode) {
    if (binding.value.action) {
      if (!authHelpers.canAction(binding.value.slug, binding.value.action))
        el.style.display = "none";
    } else {
      if (!authHelpers.canNavigate(binding.value.slug))
        el.style.display = "none";
    }
  },
});

new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app')
