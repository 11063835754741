import axios from "axios";
class AuthHelpers {
  setAccessToken(token) {
    localStorage.setItem("accessToken", token);
  }

  setAccessTokenType(type) {
    localStorage.setItem("tokenType", type);
  }

  setUser(user) {
    localStorage.setItem("user", JSON.stringify(user));
  }

  isUserLoggedIn() {
    return localStorage.getItem("user") && localStorage.getItem("accessToken")
      ? true
      : false;
  }

  isUserTemp() {
    return localStorage.getItem("tokenType") === "temp" ? true : false;
  }

  getAccessToken() {
    return localStorage.getItem("accessToken") || null;
  }

  getAccessTokenType() {
    return localStorage.getItem("tokenType") || null;
  }

  getUser() {
    return JSON.parse(localStorage.getItem("user")) || null;
  }

  testTokenExpiration(tokenValue) {
    if (!tokenValue) {
      return false;
    }
    try {
      // parse token and extract expiration date
      const parsedToken = JSON.parse(atob(tokenValue.split(".")[1]));
      // convert dates to UTC 00:00
      const currentDateUTC = new Date();
      currentDateUTC.setTime(
        currentDateUTC.getTime() + currentDateUTC.getTimezoneOffset() * 60000
      );
      const tokenDateUTC = new Date(parsedToken.exp * 1000); // convert exp to milliseconds
      tokenDateUTC.setTime(
        tokenDateUTC.getTime() + tokenDateUTC.getTimezoneOffset() * 60000
      );

      return currentDateUTC < tokenDateUTC;
    } catch (err) {
      console.error(err);
      return false;
    }
  }

 async fetchTokenExpiration(){

  }

   checkTokenExpiration(){
    let status ;

  return status
  }

  getUserAbilities() {
    return this.getUser().permissions;
  }
  getUserRole() {
    return this.getUser().role.slug;
  }

  canNavigate(slug) {
    return this.canAction(slug, "S");
  }

  canAction(slug, action) {
    var abilities = this.getUserAbilities();
    return abilities
      .find((el) => el.slug == slug)
      ?.subPermissions.some((el) => el == action);
  }
  hasRole(roleSlug){
    var role=this.getUserRole();
    return roleSlug==role;
  }


  reset() {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("user");
    localStorage.removeItem("tokenType");
  }
}

const authHelpers = new AuthHelpers();
export default authHelpers;
