export const RECORD_BY_ID = id => `/attendance/student/${id}`
export const RECORD = '/attendance/student/';

export const CONFIRM=(id)=>`/procedure-appointments/change-status/${id}`;
export const PROCEDURE=`/procedure-appointments`;
export const PROCEDURE_BY_ID=(id)=>`/procedure-appointments/${id}`;

export const EVENT_BY_ID = (id) => `/events/${id}`;
export const EVENT=`/events`;

export const ACTIVITE_BY_ID = (id) => `/activities/${id}`;
export const ACTIVITE = `/activities`;

export const DECISION=`/action-appointments`;
export const DECISION_BY_ID=(id)=>`/action-appointments/${id}`;

export const HALLS_SIRVICE_BY_ID=(id)=>`/`
export const HALLS_SIRVICE=`/`
export const HALLS_APPOINTMENTS=(id)=>`/hall-appointments/${id}`;

export const HALLS_OCCUPANCIES_BY_ID=(id)=>`/hall-occupancies/${id}`;
export const HALLS_OCCUPANCIES=`/hall-occupancies`;

export const EVALUATION=`/`;
export const EVALUATION_BY_ID=(id)=>`//${id}`;

export const SERVICES=`/services`;
export const SERVICES_BY_ID=(id)=>`/services/${id}`;

export const CONFIRM_AC=(id)=>`/archive-requests/change-status/${id}`;

export const APPOINTMENTS_OCCUPANCIES=`/hall-occupancy-appointments`;
export const APPOINTMENTS_OCCUPANCIES_Id=(id)=>`/hall-occupancy-appointments/${id}`;

export const REQUESTS_ARCHIVES_BY_ID=(id)=>`/archive-requests/${id}`;
export const REQUESTS_ARCHIVES=`/archive-requests`;


export const COMPLETED_HALL_APPOINTMENTS=(id)=>`/hall-appointments/change-status/${id}`;
export const COMPLETED_STUDIO_APPOINTMENTS=(id)=>`/studio-appointments/change-status/${id}`;

export const VISITORS=`/visitors`;
export const VISITORS_BY_ID=(id)=>`/visitors/${id}`;

export const OFFICE_BY_USER=`/office-by-user`;
export const CHANGE_STATUS =(id)=>`/change-status/${id}`
export const NEXT_VISITOR=(id)=>`/next-visitor/${id}`
export const CHECK_STATUS=(id)=>`/check-status/${id}`
export const CURRENT_VISITORS=(id)=>`/current-visitors/${id}`
export const TERMINATION_OF_SERVICE=`/update-visitor-by-office`

export const GENERAL_INFORMATION = `/general-information`;
export const FINANCIAL_PRODUCTS = "/loans";
export const MAYORS = "/mayors";
export const COMMUNITY_COMMITTEES = "/committees"

