export const RECORD_BY_ID = (id) => `/attendance/student/${id}`;
export const RECORD = "/attendance/student/";
export const HALLS = "/halls";
export const HALLS_BY_ID = (id) => `/halls/${id}`;
export const OFFICES_BY_ID = (id) => `/offices/${id}`;
export const OFFICES = `/offices`;
export const SERVICES_BY_ID=(id)=>`/office/${id}/assign-services`
export const SERVICES=`/select/procedures-and-services`;

export const ABOUT_BY_ID=(id)=>`/abouts/${id}`;
export const ABOUT=`/abouts`;

export const VERSIONS_BY_ID=(id)=>`/versions/${id}`;
export const VERSIONS=`/versions`;

export const BENEFICIARY=`beneficiaries`;
export const BENEFICIARY_BY_ID=(id)=>`/beneficiaries/${id}`;

export const USER=`/users`;
export const USER_BY_ID=(id)=>`/users/${id}`;

export const PASSWORD=(id)=>`/users/change-password/${id}`

export const ROLE_BY_ID=(id)=>`/roles/${id}`;
export const ROLE=`/roles`;

export const USEROFFICE_BY_ID=(id)=>`/office/${id}/assign-user`
export const USEROFFICE=(id)=>`office-user/${id}`;

export const ARCHIVE_CATEGORIES_BY_ID=(id)=>`/${id}`;
export const ARCHIVE_CATEGORIES=`/`;

export const CHECK_PHONE='/check-phone'