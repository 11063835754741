import * as ep from "../../endpoints";
import api from "@/libs/axios";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Vue from "vue";
export default {
  namespaced: true,
  state: {
    loading: false,
  },
  getters: {
    loading(state) {
      return state.loading;
    },
  },
  mutations: {},
  actions: {

    async update(context, payload) {
      context.state.loading = true;
     return await api.post(ep.Map_BY_ID(payload.slug,payload.id), payload.fd).then((res) => {
        context.state.loading = false;
        Vue.$toast({
          component: ToastificationContent,
          props: {
            title: res.data.message,
           icon: res.data.status ? "CheckIcon": "XIcon",
            variant: res.data.status ? "success" :"danger",
          },
        });
        return res
      });
    },
    async create(context, payload) {
      context.state.loading = true;
     return await api
        .post(ep.Map(payload.slug), payload.fd)
        .then((res) => {
          context.state.loading = false;
          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: res.data.message,
              icon: res.data.status ? "CheckIcon": "XIcon",
              variant: res.data.status ? "success" :"danger",
            },
          });
          return res
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          context.state.loading = false;
        });
    },
    async delete(context, payload) {
      context.state.loading = true;
      await api.delete(ep.Map_BY_ID(payload.slug, payload.id)).then((res) => {
        context.state.loading = false;
        Vue.$toast({
          component: ToastificationContent,
          props: {
            title: res.data.message,
            icon: res.data.status ? "CheckIcon": "XIcon",
            variant: res.data.status ? "success" :"danger",
          },
        });
      });
    },
  },
};
