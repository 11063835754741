import * as ep from "../../endpoints";
import api from "@/libs/axios";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Vue from "vue";
export default {
  namespaced: true,

  state: {
    currentVisitor:[],
    currentVisitorsLoading:false,
    loading: false,
    loadingNextBeneficiarys: false,
    loadingTerminationOfService:false,
    id: "",
    count_waiting: "",
    nomberOffice: "",
    service: "",
    visitor_id: "",
    available: false,
  },
  getters: {
    available(state) {
      return state.available;
    },
    loading(state) {
      return state.loading;
    },
    nomberOffice(state) {
      return state.nomberOffice;
    },
    loadingNextBeneficiarys(state) {
      return state.loadingNextBeneficiarys;
    },
    id(state) {
      return state.id;
    },
    count_waiting(state) {
      return state.count_waiting;
    },
    service(state) {
      return state.service;
    },
    visitor_id(state) {
      return state.visitor_id;
    },
    currentVisitor(state){
      return state.currentVisitor
    },
    currentVisitorsLoading(state){
      return state.currentVisitorsLoading
    },
    loadingTerminationOfService(state){
      return state.loadingTerminationOfService
    }
  },
  mutations: {
    setCurrentVisitor(state,payload){
      console.log("xyz",payload)
      state.currentVisitor=payload;
    }
  },
  actions: {
    async getDataOffice(context) {
      context.state.loading = true;
      await api.get(ep.OFFICE_BY_USER).then((res) => {
        context.state.id = res.data.data.id;
        context.state.nomberOffice = res.data.data.office_no;
        context.state.count_waiting = res.data.data.count_waiting;

        context.state.loading = false;
        context.dispatch("checkStatus")
        context.dispatch("currentVisitors",res.data.data.id)

      });
    },

    async checkStatus(context) {
      await api.get(ep.CHECK_STATUS(context.state.id)).then((res) => {
        context.state.available = res.data.data.is_available;
        context.state.service = res.data.data.service ? res.data.data.service.title : "";
        context.state.visitor_id = res.data.data.visitor_id;
      });
    },

    async changeStatus(context, payloade) {
      context.state.loading = true;
      await api
        .get(ep.CHANGE_STATUS(context.state.id), {
          params: { is_available: !context.state.available },
        })
        .then((res) => {
          context.state.loading = false;
          context.state.available = !context.state.available;
        });
    },

    async nextBeneficiarys(context, payloade) {
      context.state.loadingNextBeneficiarys = true;
      await api.get(ep.NEXT_VISITOR(payloade.id)).then((res) => {
        context.state.loadingNextBeneficiarys = false;
        context.state.count_waiting = res.data.data.count_waiting;
        context.state.service = res.data.data.service ? res.data.data.service.title : "";
        context.state.visitor_id = res.data.data.visitor_id;
        Vue.$toast({
          component: ToastificationContent,
          props: {
            title: res.data.message,
            icon: res.data.status ? "CheckIcon": "XIcon",
            variant: res.data.status ? "success" : "danger",
          },
        });
        context.dispatch("checkStatus")
        context.dispatch("currentVisitors",payloade.id)
      });
    },

    async currentVisitors(context, payloade){
      context.state.currentVisitorsLoading=true
      await api.get(ep.CURRENT_VISITORS(payloade)).then((res)=>{
        context.state.currentVisitorsLoading=false
        context.commit("setCurrentVisitor", res.data.data||[])
      })
    },

    async TerminationOfService(context, payloade){
      context.state.loadingTerminationOfService=true;
      await api.post(ep.TERMINATION_OF_SERVICE,payloade).then(res=>{
        context.dispatch("currentVisitors",context.state.id)
        context.state.loadingTerminationOfService=false;
      })
    },
    availableChange(context, payloade) {
      context.state.available = payloade;
    },

    emptyDataOffice(context){
      context.state.service="";
      context.state.visitor_id="";
    },
  },
};
