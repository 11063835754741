import * as ep from "../../endpoints";
import api from "@/libs/axios";
export default {
  namespaced: true,
  state: {
    loading: false,
    bookings:{},
    statistics:{}
  },
  getters: {
    loading(state) {
      return state.loading;
    },
    bookings(state){
        return state.bookings
    },
    statistics(state){
      return state.statistics
    }
  },
  mutations: {},
  actions: {
    async getBookings(context) {
      context.state.loading = true;
      await api.get(ep.BOOKING).then((res) => {
        context.state.loading = false;
        context.state.bookings=res.data.data
      });
    },
    async homeStatistics(context) {
      context.state.loading = true;
      await api.get(ep.HOME_STATISTICS).then((res) => {
        context.state.loading = false;
        context.state.statistics=res.data.data
      });
    },
  },
};
