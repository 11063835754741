import * as ep from "../../endpoints";
import api from "@/libs/axios";

export default {
  namespaced: true,
  state: {
    loading: false,
    loadingAddToQueue:false,
    servicesQ: [],
    procedureQ: [],
    visitor_id:0,
    day:'',
    date:'',
    time:'',
    title:'',
  },
  getters: {
    loading(state) {
      return state.loading;
    },
    visitor_id(state){
      return state.visitor_id;
    },

    title(state){
      return state.title;
    },

    day(state){
      return state.day;
    },   
    
    date(state){
      return state.date;
    },

    time(state){
      return state.time;
    },

    loadingAddToQueue(state){
      return state.loadingAddToQueue
    },
    servicesQ(state) {
      return state.servicesQ;
    },
    procedureQ(state) {
      return state.procedureQ;
    },
  },
  mutations: {},
  actions: {

    setTitle(context,payload){
      context.state.title = payload;
    },

    async getServices(context) {
      context.state.loading = true;
      await api.get(ep.SERVICES_QUEUE).then((res) => {
        context.state.loading = false;
        context.state.servicesQ = res.data.data;
      });
    },
    
    async getProcedure(context) {
      context.state.loading = true;
      await api.get(ep.PROCEDUR_QUEUE).then((res) => {
        context.state.loading = false;
        context.state.procedureQ = res.data.data;
      });
    },

    async addToQueue(context,payload) {
      context.state.loadingAddToQueue = true;
      await api.post(ep.QUEUE,payload.data).then((res) => {
        context.state.loadingAddToQueue = false;
        context.state.visitor_id= res.data.data.visitor_id
        context.state.day=res.data.data.day
        context.state.date=res.data.data.date
        context.state.time=res.data.data.time
      });
    },
  },
};
