import * as ep from "../../endpoints";
import api from "@/libs/axios";

export default {
  namespaced: true,
  state: {
    loading: false,
    loadingHall: false,
    loadingQ:false,
    events: [],
    hallOccupancy: [],
    QueueTv: [],
  },
  getters: {
    loading(state) {
      return state.loading;
    },
    loadingQ(state){
      return state.loadingQ
    },
    events(state) {
      return state.events;
    },
    hallOccupancy(state) {
      return state.hallOccupancy;
    },
    QueueTv(state) {
      return state.QueueTv;
    },
    QueueTvWithOutNullOffice(state) {
      return state.QueueTv.filter((item)=>item.office_id);
    },
  },
  mutations: {},
  actions: {
    async getEvent(context, payload) {
      context.state.loading = true;
      await api.get(ep.EVENT).then((res) => {
        context.state.loading = false;
        context.state.events = res.data.data;
      });
    },

    async getHallTv(context) {
      context.state.loadingHall = true;
      await api.get(ep.HALL_OCCUPANCY).then((res) => {
        context.state.loadingHall = false;
        context.state.hallOccupancy = res.data.data;
      });
    },
    async getQueueTv(context,payload) {
      context.state.loadingQ = payload ? false : true;
      await api.get(ep.QUEUE_TV).then((res) => {
        context.state.loadingQ = false;
        context.state.QueueTv = res.data.data;
      });
    },
  },


};
