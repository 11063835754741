import * as ep from "../../../../endpoints";
import api from "@/libs/axios";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Vue from "vue";
export default {
  namespaced: true,
  state: {
    loading: false,
    loadingBooking: false,
    activites: [],
    bookingsEvent: [],
    pagination: { total_row: 1 },
  },
  getters: {
    loading(state) {
      return state.loading;
    },
    activites(state) {
      return state.activites;
    },
    pagination(state) {
      return state.pagination;
    },
    bookingsEvent(state) {
      return state.bookingsEvent;
    },
    loadingBooking(state) {
      return state.loadingBooking;
    },
  },
  mutations: {},
  actions: {
    async getActivity(context, payload) {
      context.state.loading = true;
      await api
        .get(payload.ep, { params: { page: payload.page } })
        .then((res) => {
          context.state.pagination.total_row = res.data.pagination.total_row;
          context.state.activites = res.data.data;
          context.state.loading = false;
        });
    },

    async createBooking(context, payload) {
      context.state.loadingBooking = true;
      await api
        .post(payload.ep, payload.data)
        .then((res) => {
          context.state.loadingBooking = false;
          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: res.data.message,
              icon: res.data.status ? "CheckIcon": "XIcon",
              variant: res.data.status ? "success" :"danger",
            },
          });
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          context.state.loadingBooking = false;
        });
    },
    async deleteBookings(context, payload) {
      context.state.loadingBooking = true;
      await api.delete(payload.ep , {params:payload.data}).then((res) => {
        context.state.loadingBooking = false;
        Vue.$toast({
          component: ToastificationContent,
          props: {
            title: res.data.message,
            icon: res.data.status ? "CheckIcon": "XIcon",
            variant: res.data.status ? "success" :"danger",
          },
        });
      });
    },
    async updateBookings(context, payload) {
      context.state.loadingBooking = true;
      await api.put(payload.ep , payload.data ).then((res) => {
        context.state.loadingBooking = false;
        Vue.$toast({
          component: ToastificationContent,
          props: {
            title: res.data.message,
            icon: res.data.status ? "CheckIcon": "XIcon",
            variant: res.data.status ? "success" :"danger",
          },
        });
      });
    },
  },
};
