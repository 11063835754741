import * as ep from "../../../../endpoints";
import api from "@/libs/axios";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Vue from "vue";

export default {
  namespaced: true,
  state: {
    loading: false,
  },
  getters: {
    loading(state) {
      return state.loading;
    },
  },
  mutations: {},
  actions: {

    async update(context, payload) {
      context.state.loading = true;
      return await api.put(`${ep.MAYORS}/${payload.id}`, payload.data).then((res) => {
        context.state.loading = false;
        Vue.$toast({
          component: ToastificationContent,
          props: {
            title: res.data.message,
            icon: res.data.status ? "CheckIcon" : "XIcon",
            variant: res.data.status ? "success" : "danger",
          },
        });
        return res;
      })
    },

    async delete(context, payload) {
      context.state.loading = true;
      await api.delete(`${ep.MAYORS}/${payload.id}`).then((res) => {
        context.state.loading = false;
        Vue.$toast({
          component: ToastificationContent,
          props: {
            title: res.data.message,
            icon: res.data.status ? "CheckIcon" : "XIcon",
            variant: res.data.status ? "success" : "danger",
          },
        });
      })
    },

    async create(context, payload) {
      context.state.loading = true;
      return await api.post(ep.MAYORS, payload.data).then((res) => {
        context.state.loading = false;
        Vue.$toast({
          component: ToastificationContent,
          props: {
            title: res.data.message,
            icon: res.data.status ? "CheckIcon" : "XIcon",
            variant: res.data.status ? "success" : "danger",
          },
        });
        return res;
      })
    },
  },
};
