export default [
  {
    path: "/dashboard",
    component: () => import("./moduleLayout.vue"),
    children: [
      {
        path: "home",
        meta:{
          slug:"home",
        },
        name:"dashboard-home",
        component: () => import("./pages/home/index.vue"),
      },
      {
        path: "events",
        meta:{
          slug:"appManagement-events",
        },
        name:"dashboard-events",
        component: () => import("./pages/events/index.vue"),
      },
      {
        path: "interactive-map",
        meta:{
          slug:"appManagement-interactiveMap",
        },
        name:"interactive-map",
        component: () => import("./pages/interactive-map/index.vue"),
      },
      {
        path: "activite",
        meta:{
          slug:"appManagement-activities",
        },
        name:"dashboard-activite",
        component: () => import("./pages/activite/index.vue"),
      },
      {
        path: "procedure",
        meta:{
          slug:"appManagement-procedures",
        },
        name:"dashboard-procedure",
        component: () => import("./pages/procedure/index.vue"),
      },
      {
        path: "archives",
        meta:{//
          slug:"appManagement-archive",
        },
        name:"archives",
        component: () => import("./pages/archives/index.vue"),
      },
      {
        path: "lecturers",
        meta:{
          slug:"",
        },
        name:"dashboard-lecturers",
        component: () => import("./pages/lecturers/index.vue"),
      },
      {
        path: "opportunities",
        meta:{
          slug:"appManagement-opportunities",
        },
        name:"dashboard-opportunities",
        component: () => import("./pages/jobs/index.vue"),
      },
      {
        path: "lecturers-info/:id",
        meta:{
          slug:"",
        },
        name:"lecturers-info",
        props:true,
        component: () => import("./pages/lecturers/info-lecturers.vue"),
      },
      {
        path: "notifictions",
        meta:{
          slug:"appManagement-notifications",
        },
        name: "notifictions",
        props:true,
        component: () => import("./pages/notifictions/index.vue"),
      },
      {
        path: "awareness-sessions",
        meta:{
          slug:"appManagement-awarenessSections",
        },
        name: "awareness-sessions",
        component: () => import("./pages/activite/awareness-sessions/index.vue"),
        props: true,
      },
      {
        path: "interactive-museum",
        meta:{
          slug:"appManagement-interactiveMuseum",
        },
        name: "interactive-museum",
        component: ()=> import("./pages/activite/interactive-museum/index.vue")
      },
      {
        path: "training-heritage",
        meta:{
          slug:"appManagement-traditionalTrainings",
        },
        name: "training-heritage",
        component: ()=> import("./pages/activite/training-heritage/index.vue")
      },
    ],
  },
];
