export default [
  {
    path: "/manage-center",
    component: () => import("./moduleLayout.vue"),
    children: [
      {
        path: "archive-request",
        name: "archive-request",
        props: true,
        component: () =>
          import("./pages/requests/requests-page/archives/index.vue"),
        meta: {
          slug: "centerManagement-orders-archiveRequests",
        }
      },

      {
        path: "bookings-awareness-sessions",
        name: "bookings-awareness-sessions",
        props: true,
        component: () =>
          import("./pages/bookings/bookings-page/events-activity/index.vue"),
          meta: {
            slug: "centerManagement-books-AwarenessSessions",
          }
      },
      {
        path: "booking-awareness-sessions/:id",
        name: "booking-awareness-sessions",
        props: true,
        component: () =>
          import(
            "./pages/bookings/bookings-page/events-activity/users-event.vue"
          ),
          meta: {
            slug: "centerManagement-books-AwarenessSessions",
          }
      },
      {
        path: "bookings-interactive-museum",
        name: "bookings-interactive-museum",
        props: true,
        component: () =>
          import("./pages/bookings/bookings-page/events-activity/index.vue"),
          meta: {
            slug: "centerManagement-books-interactiveMuseum",
          }
      },
      {
        path: "booking-interactive-museum/:id",
        name: "booking-interactive-museum",
        props: true,
        component: () =>
          import(
            "./pages/bookings/bookings-page/events-activity/users-event.vue"
          ),
          meta: {
            slug: "centerManagement-books-interactiveMuseum",
          }
      },
      {
        path: "bookings-training-heritage",
        name: "bookings-training-heritage",
        props: true,
        component: () =>
          import("./pages/bookings/bookings-page/events-activity/index.vue"),
          meta: {
            slug: "centerManagement-books-traditionalTrainings",
          }
      },
      {
        path: "booking-training-heritage/:id",
        name: "booking-training-heritage",
        props: true,
        component: () =>
          import(
            "./pages/bookings/bookings-page/events-activity/users-event.vue"
          ),
          meta: {
            slug: "centerManagement-books-traditionalTrainings",
          }
      },

      {
        path: "bookings-activites",
        name: "bookings-activites",
        props: true,
        component: () =>
          import("./pages/bookings/bookings-page/events-activity/index.vue"),
          meta: {
            slug: "centerManagement-books-activityAppointments",
          }
      },
      {
        path: "bookings-activite/:id",
        name: "bookings-activite",
        props: true,
        component: () =>
          import(
            "./pages/bookings/bookings-page/events-activity/users-event.vue"
          ),
          meta: {
            slug: "centerManagement-books-activityAppointments",
          }
      },
      {
        path: "bookings-events",
        name: "bookings-events",
        props: true,
        component: () =>
          import("./pages/bookings/bookings-page/events-activity/index.vue"),
          meta: {
            slug: "centerManagement-books-eventAppointments",
          }
      },
      {
        path: "bookings-event/:id",
        name: "bookings-event",
        props: true,
        component: () =>
          import(
            "./pages/bookings/bookings-page/events-activity/users-event.vue"
          ),
          meta: {
            slug: "centerManagement-books-eventAppointments",
          }
      },
      {
        path: "bookings-halls",
        name: "bookings-halls",
        props: true,
        component: () =>
          import("./pages/bookings/bookings-page/halls/index.vue"),
          meta: {
            slug: "centerManagement-books-hallAppointments",
          }
      },
      {
        path: "bookings-procedure",
        name: "bookings-procedure",
        props: true,
        component: () =>
          import("./pages/bookings/bookings-page/procedure/index.vue"),
          meta: {
            slug: "centerManagement-books-procedureAppointments",
          }
      },
      {
        path: "/reception",
        name: "reception",
        props: true,
        component: () => import("./pages/reception/index.vue"),
        meta: {
          slug: "centerManagement-reception",
        }
      },
      {
        path: "/halls-details",
        name: "halls-details",
        props: true,
        component: () => import("./pages/reception/detilsHalls.vue"),
        meta: {
          slug: "",
        }
      },
      {
        path: "/halls-occupancy",
        name: "halls-occupancy",
        props: true,
        component: () => import("./pages/halls-occupancy/index.vue"),
        meta: {
          slug: "centerManagement-holesOccupancy",
        }
      },
      {
        path: "/manage-evaluation",
        name: "manage-evaluation",
        props: true,
        component: () => import("./pages/evaluation/index.vue"),
        meta: {
          slug: "centerManagement-evaluation",
        }
      },

      {
        path: "/office",
        name: "office",
        props: true,
        component: () => import("./pages/office/index.vue"),
        meta: {
          slug: "centerManagement-office",
        }
      },

      {
        path: "/visitors",
        name: "visitors",
        props: true,
        component: () => import("./pages/visitors/index.vue"),
        meta: {
          slug: "centerManagement-visitors",
        }
      },
      {
        path: "general-information",
        name: "general-information",
        component: () => import("./pages/legal-awareness/pages/general-information/index.vue"),
        props: true,
        meta: {
          slug: "appManagement-LegalAwareness-generalInformation",
        },
      },
      {
        path: "community-initiative",
        name: "community-initiative",
        component: () => import("./pages/community-services/pages/community-initiative/index.vue"),
        props: true,
        meta: {
          slug: "appManagement-socialServices-socialCommittees",
        }
      },
      {
        path: "community-committees",
        name: "community-committees",
        component: () => import("./pages/community-services/pages/community-committees/index.vue"),
        props: true,
        meta: {
          slug: "appManagement-socialServices-socialCommittees",
        }
      },
      {
        path: "mayors",
        name: "mayors",
        component: () => import("./pages/community-services/pages/mayors/index.vue"),
        props: true,
        meta: {
          slug: "appManagement-socialServices-mayors",
        }
      },
      {
        path: "recording-studio",
        name: "recording-studio",
        component: () => import("./pages/bookings/bookings-page/halls/index.vue"),
        meta: {
          slug: "centerManagement-books-studio",
        }
      },
      {
        path: "financial-products",
        name: "financial-products",
        component: () => import("./pages/financial-products/index.vue"),
        meta: {
          slug: "centerManagement-financingProducts",
        }
      },
      {
        path: "manage-services",
        name: "manage-services",
        slug: "center-service",
        component: () => import("./pages/services/index.vue"),
        meta: {
          slug: "centerManagement-centerServices",
        }
      },
      {
        path: "self-tourist-tours",
        name: "self-tourist-tours",
        component: () => import("./pages/discover-ancient-city/pages/self-tourist-tours/index.vue"),
        meta: {
          slug: "appManagement-discoverOldCity-selfTours",
        }
      },
      {
        path: "tourist-facilities",
        name: "tourist-facilities",
        component: () => import("./pages/discover-ancient-city/pages/tourist-facilities/index.vue"),
        meta: {
          slug: "appManagement-discoverOldCity-touristFacilities",
        }
      },
      {
        path: "main-attraction",
        name: "main-attraction",
        component: () => import("./pages/discover-ancient-city/pages/main-attraction/index.vue"),
        meta: {
          slug: "appManagement-discoverOldCity-mainPlaces",
        }
      },
    ],
  },
];
