import Vue from "vue";
import router from "@/router";

// axios
import axios from "axios";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import authHelpers from "@/utils/auth-helpers";
//   baseURL: "https://dev.back.oldaleppo.warshatec.com/api/dashboard",

const axiosIns = axios.create({
  baseURL: "https://dev.back.oldaleppo.warshatec.com/api/dashboard",
  headers: {
    accept: "application/json",
    lang: "ar",
    "access-control-allow-origin": "*",
    "Access-Control-Allow-Headers": "*",
  },
  validateStatus: () => true,
});

axiosIns.interceptors.request.use(function (request){
  request.headers["Authorization"] = `Bearer ${localStorage.getItem('accessToken')}`
  return request
})


axiosIns.interceptors.response.use(function (response) {
  if (response.status === 422 || response.status === 401) {
    if (response.status === 401) {
      if(router.currentRoute.path.includes("auth")){
        return
      }else{
        authHelpers.reset();
        router.push({ name: "auth-login" });
        Vue.$toast({
          component: ToastificationContent,
          props: {
            title: response.message,
            icon: "AlertCircleIcon",
            text: "لا يمكنك الوصول لهذه الصفحة",
            variant: "danger",
          },
        });
      }
   
    } else {
      Vue.$toast({
        component: ToastificationContent,
        props: {
          // title: "خطأ",
          icon: "AlertCircleIcon",
          text: response.data.message,
          variant: "danger",
        },
      });
    }
  }
  return response;
});
Vue.prototype.$axios = axiosIns;

export default axiosIns;
