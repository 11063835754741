import * as ep from "../../endpoints";
import api from "@/libs/axios";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Vue from "vue";
import { set } from 'lodash'
export default {
  namespaced: true,


  state: {
    beneficiary: { "id": '', "first_name": "", "last_name": "", "phone_number": "", "dob": "", "gender": "", "guest_session_id": '', "status": "" },
    pendingBeneficiary: [],
    loading: false,
    pendingLoading: false,
    loadingCheck: false,
    message: "",
    idBeneficiary: '',
    parent_id:'',
    formBeneficiary: {
      id: '',
      first_name: '',
      last_name: '',
      phone_number: '',
      dob: '',
      gender: '',
      career: '',
      purpose_of_visit: '',
      services: '',
      occupation: ''
    },
    procedureAppointmentsCount:null,
    internalActivityAppointmentsCount:null,
  },
  getters: {
    parent_id(state){
      return state.parent_id
    },
    loading(state) {
      return state.loading;
    },
    formBeneficiary(state) {
      return state.formBeneficiary
    },
    idBeneficiary(state) {
      return state.idBeneficiary
    },
    loadingCheck(state) {
      return state.loadingCheck
    },
    pendingBeneficiary(state) {
      return state.pendingBeneficiary
    },
    pendingLoading(state) {
      return state.pendingLoading
    },
    message(state) {
      return state.message
    },
    beneficiary(state){
      return state.beneficiary
    },
    procedureAppointmentsCount(state){
      return state.procedureAppointmentsCount;
    },
    internalActivityAppointmentsCount(state){
      return state.internalActivityAppointmentsCount;
    },
  },
  mutations: {
    setBenficary(state, { key, value }) {
      set(state.formBeneficiary, key, value)
    },
    setIdBeneficiary(state, payload) {
      state.pendingBeneficiary.id = payload
    },
    setProcedureAppointmentsCount(state,count){
      state.procedureAppointmentsCount=count;
    },
    setInternalActivityAppointmentsCount(state,count){
      state.internalActivityAppointmentsCount=count;
    },
  },
  actions: {

    setParentId(context, payload){
      context.state.parent_id=payload
    },

    refreshForm(context, payload) {
      context.state.message = ''
      context.state.idBeneficiary=''
      context.state.formBeneficiary = {
        id: '',
        first_name: '',
        last_name: '',
        phone_number: '',
        dob: '',
        gender: '',
        career: '',
        purpose_of_visit: '',
        services: '',
        occupation: ''
      }
    },
    async getFormData(context, payload) {
      context.state.loadingCheck = true;
      await api.get(ep.CHECK_PHONE, { params: { phone_number: payload } }).then((res) => {
        context.state.loadingCheck = false;
        context.state.idBeneficiary = res.data.data.id || ''
        context.state.formBeneficiary = res.data.data || {
          id: '',
          first_name: '',
          last_name: '',
          phone_number: payload,
          dob: '',
          gender: '',
          career: '',
          purpose_of_visit: '',
          services: '',
          occupation: ''
        }
        context.state.message = res.data.message
        Vue.$toast({
          component: ToastificationContent,
          props: {
            title: res.data.message,
            icon: res.data.status ? "CheckIcon" : "XIcon",
            variant: res.data.status ? "success" : "danger",
          },
        });
        
        context.dispatch('getPending',res.data.data.id)
      });
    },

    async getReservationsBooking(){
      
    },

    async getPending(context,payload) {
      context.state.pendingLoading = true;
      await api.get(`/get-pending-visitors/${payload}`).then(res => {
        context.state.pendingBeneficiary = res.data.data
        context.state.pendingLoading = false;
      })
    },

    async show(context, id) {
      context.state.loading = true;
      await api.get(ep.BENEFICIARY_BY_ID(id)).then((res) => {
        context.state.beneficiary=res.data.data
        context.state.loading = false;
      });
    },

    async update(context, payload) {
      context.state.loading = true;
      await api.put(ep.BENEFICIARY_BY_ID(payload.id), payload).then((res) => {
        context.state.loading = false;
        Vue.$toast({
          component: ToastificationContent,
          props: {
            title: res.data.message,
            icon: res.data.status ? "CheckIcon" : "XIcon",
            variant: res.data.status ? "success" : "danger",
          },
        });
      });
    },
    async create(context, payload) {
      context.state.loading = true;
      await api
        .post(ep.BENEFICIARY, payload)
        .then((res) => {
          context.state.loading = false;
          console.log('res.data.data.id', res.data.data.id)
          context.state.idBeneficiary = res.data.data?.id

          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: res.data.message,
              icon: res.data.status ? "CheckIcon" : "XIcon",
              variant: res.data.status ? "success" : "danger",
            },
          });
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          context.state.loading = false;
        });
    },
    async delete(context, payload) {
      context.state.loading = true;
      await api.delete(ep.BENEFICIARY_BY_ID(payload.id)).then((res) => {
        context.state.loading = false;
        Vue.$toast({
          component: ToastificationContent,
          props: {
            title: res.data.message,
            icon: res.data.status ? "CheckIcon" : "XIcon",
            variant: res.data.status ? "success" : "danger",
          },
        });
      });
    },

    async updateUser(context, payload) {
      context.state.loading = true;
      await api.put(ep.USER_BY_ID(payload.id), payload.data).then((res) => {
        context.state.loading = false;
        Vue.$toast({
          component: ToastificationContent,
          props: {
            title: res.data.message,
            icon: res.data.status ? "CheckIcon" : "XIcon",
            variant: res.data.status ? "success" : "danger",
          },
        });
      });
    },
    async createUser(context, payload) {
      context.state.loading = true;
      await api
        .post(ep.USER, payload.data)
        .then((res) => {
          context.state.loading = false;
          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: res.data.message,
              icon: res.data.status ? "CheckIcon" : "XIcon",
              variant: res.data.status ? "success" : "danger",
            },
          });
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          context.state.loading = false;
        });
    },
    async deleteUser(context, payload) {
      context.state.loading = true;
      await api.delete(ep.USER_BY_ID(payload.id)).then((res) => {
        context.state.loading = false;
        Vue.$toast({
          component: ToastificationContent,
          props: {
            title: res.data.message,
            icon: res.data.status ? "CheckIcon" : "XIcon",
            variant: res.data.status ? "success" : "danger",
          },
        });
      });
    },
    async changePassword(context, payload) {
      context.state.loading = true;
      await api.post(ep.PASSWORD(payload.id), payload.fd).then((res) => {
        context.state.loading = false;
        Vue.$toast({
          component: ToastificationContent,
          props: {
            title: res.data.message,
            icon: "AlertCircleIcon",
            variant: res.data.status ? "success" : "danger",
          },
        });
      });
    },
    async getInternalActivityAppointmentsCount(context, payload) {
      context.state.loading = true;
      await api.get(`/get-reservations/${context.state.formBeneficiary.id}?slug=I`).then((res) => {
        context.state.loading = false;
        context.state.internalActivityAppointmentsCount=res.data.data.internalActivityAppointmentsCount;
      });
    },
    async getProcedureAppointmentsCount(context, payload) {
      context.state.loading = true;
      await api.get(`/get-reservations/${context.state.formBeneficiary.id}?slug=P`).then((res) => {
        context.state.loading = false;
        context.state.procedureAppointmentsCount=res.data.data.procedureAppointmentsCount;
      });
    },

  },
};
